<template>
  <div class="all-height">
    <div class="">
      <v-card loader-height="2" class="shadow-off" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex flex-column">
            <section id="container" style="height:75vh">
              <iframe height="100%" width="100%" src="https://app.powerbi.com/view?r=eyJrIjoiOWYyN2U1ZmEtZWRiYi00ZjlkLWI3MjEtZGNjNWNhN2NiYmQ3IiwidCI6IjViNjNiMjEyLTIwM2QtNGJjNi1hZTBiLThkNWZjOGUyNjRiMSIsImMiOjEwfQ%3D%3D" frameborder="0" title="app.powerbi.com"></iframe>
            </section>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import * as pbi from "powerbi-client"

export default {
  name: 'commentarydashboard',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      userid: '',
      embedUrl: null,
      accessToken: null,
      sampleReportUrl: 'https://playgroundbe-bck-1.azurewebsites.net/Reports/SampleReport'
    }
  },
  
  activated() {
  },
  mounted() {
  },
  methods: {
    async initializePowerBI() {
      const sampleReportUrl = this.sampleReportUrl;

      const reportConfigResponse = await fetch(sampleReportUrl);

      if (!reportConfigResponse.ok) {
        console.error('Failed to fetch config for report.');
        console.error('Status:', reportConfigResponse.status, reportConfigResponse.statusText)    
        return;
      }

      const reportConfig = await reportConfigResponse.json();

      console.log('The access token is set. Loading the Power BI report');

      this.embedUrl = reportConfig.EmbedUrl;
      this.accessToken = reportConfig.EmbedToken.Token;
    },
    refreshData() {
      this.initializePowerBI().then(() => {
        const permissions = pbi.models.Permissions.All;

        const config = {
          type: 'report',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: this.accessToken,
          embedUrl: this.embedUrl,
          pageView: 'fitToWidth',
          permissions: permissions,
        };

        let powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory, 
          pbi.factories.wpmpFactory, 
          pbi.factories.routerFactory
        );

        const dashboardContainer = document.getElementById('container');
        const dashboard = powerbi.embed(dashboardContainer, config);

        dashboard.off("loaded");
        dashboard.off("rendered");
        dashboard.on("error", function () {
          this.dashboard.off("error");
        });
      })
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/varianceanalysis/analysis/getaccesshierarchy", {filter: {role: this.selectedrole}}).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0) {
          this.hierarchyData = dt.data.data[0];
          
        }
        else throw new Error ("Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.loadContent();
      })
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>
